import React, { Component } from 'react';
import Moment from 'moment';

export class FetchData extends Component {
  static displayName = FetchData.name;

  constructor(props) {
    super(props);
    this.state = { forecasts: [], loading: true };
  }

  componentDidMount() {
    this.populateWeatherData();
  }

  static renderForecastsTable(clients) {
    return (
        <div>
            {clients.map(client =>
                <div>
                    <h4>{client.name}</h4>
                    {client.domains.map(domain =>
                        <div>
                            <h6>{domain.name}</h6>
                            <table className="table table-striped" aria-labelledby="tableLabel">
                                <thead>
                                    <tr>
                                        <th>Host</th>
                                        <th>User</th>
                                        <th>Desk ID</th>
                                        <th>Desk PW</th>
                                        <th>Description</th>
                                        <th>Last Seen</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {domain.remoteClients.map(remoteclient =>
                                        <tr key={remoteclient.host}>
                                            <td>{remoteclient.host}</td>
                                            <td>{remoteclient.user}</td>
                                            <td>{remoteclient.deskid}</td>
                                            <td title={remoteclient.deskpw}>{remoteclient.deskpw.length > 10 ? `${remoteclient.deskpw.substring(0, 10)}...` : remoteclient.deskpw}<button onClick={() => { navigator.clipboard.writeText(remoteclient.deskpw) }}>Copy</button></td>
                                            <td>{remoteclient.description}</td>
                                            <td>{Moment(remoteclient.lastseen).format('DD.MM.YYYY HH:mm')}</td>
                                            <td><button onClick={() => { window.open('eibdesk://' + remoteclient.deskid + ';' + remoteclient.deskpw, '_self').focus() }}>Connect</button></td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    )}
                    
                </div>
            )}
        </div>
    );
  }

  render() {
    let contents = this.state.loading
      ? <p><em>Loading...</em></p>
      : FetchData.renderForecastsTable(this.state.forecasts);

    return (
      <div>
        {contents}
      </div>
    );
  }

  async populateWeatherData() {
      const response = await fetch('remoteclients',{
          method: 'get',
          headers: new Headers({
              'Authorization': 'Bearer ' + localStorage.getItem('access_token')
          })
      });
    const data = await response.json();
    this.setState({ forecasts: data, loading: false });
  }
}
