import React, { Component } from 'react';
import { Route, Routes } from 'react-router-dom';
import AppRoutes from './AppRoutes';
import { Layout } from './components/Layout';
import './custom.css';
import { AzureAD, AuthenticationState } from 'react-aad-msal';
import { authProvider } from './authProvider';

export default class App extends Component {
  static displayName = App.name;

  render() {
      return (
          <div className="App">
            
              <AzureAD provider={authProvider} forceLogin={true}>
                  {
                      ({ login, logout, authenticationState, error, accountInfo }) => {
                          switch (authenticationState) {
                              case AuthenticationState.Authenticated:
                                  localStorage.setItem('access_token', accountInfo.jwtIdToken)
                                  return (
                                      <Layout>
                                          <p>
                                              <button onClick={logout}>Logout</button>
                                          </p>
                                          <Routes>
                                              {AppRoutes.map((route, index) => {
                                                  const { element, ...rest } = route;
                                                  return <Route key={index} {...rest} element={element} />;
                                              })}
                                          </Routes>

                                      </Layout>
                                     
                                  );
                              case AuthenticationState.Unauthenticated:
                                  return (
                                      <div>
                                          {error && <p><span>An error occured during authentication, please try again!</span></p>}
                                          <p>
                                              <span>Hey stranger, you look new!</span>
                                              <button onClick={login}>Login</button>
                                          </p>
                                      </div>
                                  );
                              case AuthenticationState.InProgress:
                                  return (<p>Authenticating...</p>);
                          }
                      }
                  }
              </AzureAD>
          </div>
       
    );
  }
}

