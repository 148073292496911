import React, { Component } from 'react';
import Moment from 'moment';
import { decode as base64_decode, encode as base64_encode } from 'base-64';

export class Home extends Component {
  static displayName = Home.name;
    constructor(props) {
        super(props);
        this.state = { forecasts: [], loading: true };
    }

    componentDidMount() {
        this.populateWeatherData();
        setInterval(() => this.populateWeatherData(), 1000)
        
    }
    static renderForecastsTable(clients) {
        return (
            <div>
                <table>
                    <tr><td width='100'>Server</td><td>195.201.168.80</td></tr>
                    <tr><td>Key</td><td>7mJhBjxQUNVXbhw1R1LR5tqcZxebpP7qPnZci8uCoPc=</td></tr>
                </table>
                <br />      
                <div>
                    {clients.map(client =>
                        <div>
                            <h4>{client.name}</h4>
                            {client.domains.map(domain =>
                                <div>
                                    <h6>{domain.name}</h6>
                                    <table className="table" aria-labelledby="tableLabel">
                                        <thead>
                                            <tr>
                                                <th></th>
                                                <th>Host</th>
                                                <th>User</th>
                                                <th>Desk ID</th>
                                                <th>Desk PW</th>
                                                <th>Description</th>
                                                <th>Last Seen</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {domain.remoteClients.map(remoteclient =>
                                                <tr key={remoteclient.host}>
                                                    <td>{Moment().diff(Moment(remoteclient.lastseen)) < 30000 ? <div style={{ width: 20, height: 20, backgroundColor: 'green' }} ></div> : <div style={{ width: 20, height: 20, backgroundColor: 'red' }} ></div>}</td>
                                                    <td>{remoteclient.host}</td>
                                                    <td>{remoteclient.user}</td>
                                                    <td>{remoteclient.deskid}</td>
                                                    <td title={remoteclient.deskpw}>{remoteclient.deskpw.length > 10 ? `${remoteclient.deskpw.substring(0, 10)}...` : remoteclient.deskpw}<button onClick={() => { navigator.clipboard.writeText(remoteclient.deskpw) }}>Copy</button></td>
                                                    <td>{remoteclient.description}</td>
                                                    <td>{Moment(remoteclient.lastseen).format('DD.MM.YYYY HH:mm')}</td>
                                                    <td>{Moment().diff(Moment(remoteclient.lastseen)) < 30000 ? <button onClick={() => { window.open('eibdesk://' + remoteclient.deskid + ';' + remoteclient.deskpw, '_self').focus() }}>Connect</button> : <a/>}</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>

                            )}

                        </div>
                    )}
                </div>
                <div>
                    {clients.map(client =>
                        <div>
                            <h4>{client.name}</h4>
                            {client.domains.map(domain =>
                                <div>
                                    <h6>{domain.name}</h6>
                                    <div>

                                        <div style={{ overflow: 'hidden' }}>
                                            {domain.remoteClients.map(remoteclient =>

                                                <div key={remoteclient.host} style={{ borderStyle: 'solid', float: 'left', width: 356, height: 235, margin: 20, backgroundImage: `url("` + "data:image/gif;base64," + (remoteclient.screen) + `")`, backgroundSize: 324, color: 'darkred', fontSize: 20, fontWeight: 700, padding: 5 }} onClick={() => { window.open('eibdesk://' + remoteclient.deskid + ';' + remoteclient.deskpw, '_self').focus() }} >

                                                    {remoteclient.host} ({remoteclient.deskid})<br />
                                                 
                                                    User: {remoteclient.user}<br />
                                                    Host: {remoteclient.description}<br />
                                                    LastSeens: {Moment(remoteclient.lastseen).format('HH:mm')}<br />
                                                    CPU: {remoteclient.cpu}%<br />
                                                    Memory: {remoteclient.memory} GB<br />
                                                    Disk: {remoteclient.disk} GB

                                                </div>
                                            )}
                                        </div>
                                    </div>

                                </div>

                            )}

                        </div>
                    )}
                </div>
            </div>
        );
    }

    render() {
        let contents = this.state.loading
            ? <p><em>Loading...</em></p>
            : Home.renderForecastsTable(this.state.forecasts);

        return (
            <div>
                {contents}
            </div>
        );
    }

    async populateWeatherData() {
        const response = await fetch('remoteclients', {
            method: 'get',
            headers: new Headers({
                'Authorization': 'Bearer ' + localStorage.getItem('access_token')
            })
        });
        const data = await response.json();
        this.setState({ forecasts: data, loading: false });
    }
}
